import { MydraLogo } from '@/app/icons/MydraLogo'
import { EXTERNAL_LINKS, WEBSITE_LINKS } from '@/app/lib/constants'
import { LanguageSelector } from '@/app/modules/shared/components/language-selector'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

type FooterProps = React.HtmlHTMLAttributes<HTMLDivElement>

export function Footer({ ...props }: FooterProps) {
  const t = useTranslations('Footer')

  return (
    <footer {...props} className={`bg-mydra-black ${props.className ?? ''}`}>
      <div className="container flex overflow-x-hidden flex-col gap-4 pt-8 pb-5 mt-auto text-sm text-white md:grid md:grid-cols-12 md:gap-x-8 md:pt-16 md:gap-y-0">
        <MydraLogo
          className="self-start h-9 text-white md:col-span-4"
          showByStudentFinance
        />
        <nav className="hidden text-base font-medium md:row-start-1 md:row-span-4 md:block md:col-start-7 md:col-span-2">
          <header className="hidden uppercase md:block md:col-start-7 md:col-span-1">
            {t('use_cases')}
          </header>
          <ul className="flex flex-col gap-5 mt-6">
            <li>
              <Link target="_blank" href={EXTERNAL_LINKS.forIndividuals}>
                {t('for_individuals')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={EXTERNAL_LINKS.forCompanies}>
                {t('for_companies')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={EXTERNAL_LINKS.forEducationProviders}>
                {t('for_education_providers')}
              </Link>
            </li>
          </ul>
        </nav>
        <nav className="hidden text-base font-medium md:row-start-1 md:row-span-4 md:block md:col-start-9 md:col-span-2">
          <header className="hidden uppercase md:block md:col-start-7 md:col-span-1">
            {t('explore')}
          </header>
          <ul className="flex flex-col gap-5 mt-6">
            <li>
              <Link target="_blank" href={WEBSITE_LINKS.aboutUs}>
                {t('about_us')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={EXTERNAL_LINKS.careerCopilot}>
                {t('career_copilot')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={WEBSITE_LINKS.sevenStepAIFramework}>
                {t('seven_step_ai_framework')}
              </Link>
            </li>
          </ul>
        </nav>
        <nav className="hidden text-base font-medium md:row-start-1 md:row-span-4 md:block md:col-start-11 md:col-span-2">
          <header className="hidden uppercase md:block md:col-start-7 md:col-span-1">
            {t('support')}
          </header>
          <ul className="flex flex-col gap-5 mt-6">
            <li>
              <Link target="_blank" href={WEBSITE_LINKS.help}>
                {t('help')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={WEBSITE_LINKS.faqs}>
                {t('faqs')}
              </Link>
            </li>
            <li>
              <Link target="_blank" href={WEBSITE_LINKS.paymentOptions}>
                {t('payment_options')}
              </Link>
            </li>
          </ul>
        </nav>
        <address className="row-start-2 not-italic md:col-span-4 md:col-start-1 md:mt-8">
          <p className="leading-[150%]">{t('address')}</p>
        </address>
        <p className="md:col-span-4 md:col-start-1 md:mt-9 text-xs leading-[160%]">
          {t('registration_info')}
        </p>

        <div className="flex flex-col md:items-center md:gap-4 md:flex-row md:col-span-full md:col-start-1 md:mt-6">
          <div className="flex items-center">
            <Image
              src="/images/eif-logo.png"
              width={145}
              height={50}
              alt="EIF logo"
              className="w-auto h-12"
            />
            <Image
              src="/images/eif-classic-logo.png"
              width={227}
              height={64}
              alt="EIF logo"
              className="w-auto h-16"
            />
          </div>
          <p className="flex-1 text-xs leading-[150%]">{t('eif_disclaimer')}</p>
        </div>
        <hr className="opacity-20 md:mt-6 md:col-span-full" />
        <nav className="md:mt-8 md:col-span-full">
          <ul className="flex flex-col gap-4 font-medium leading-8 text-center md:gap-11 md:items-center md:flex-row">
            <li>{t('all_rights_mydra')}</li>
            <li>
              <LanguageSelector className="col-span-2" />
            </li>
            <li>
              <Link
                target="_blank"
                href={WEBSITE_LINKS.termsAndConditions}
                className="underline"
              >
                {t('terms_of_use')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={WEBSITE_LINKS.privacyPolicy}
                className="underline"
              >
                {t('privacy_policy')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={EXTERNAL_LINKS.linkedIn}
                className="underline"
              >
                LinkedIn
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}
