import * as React from 'react'

const ChevronDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 6"
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m9.313 5-3.89-3.89a.377.377 0 0 0-.534 0L1 5"
    />
  </svg>
)

export default ChevronDown
