import { getLangNameFromCode } from 'language-name-map'
import ChevronDown from '@/app/icons/ChevronDown/ChevronDown'
import {
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { useRouter } from 'next/router'
import { GlobeIcon } from '../../icons'
import Cookies from 'js-cookie'
import { GB, ES, DE, IT, PT, FR } from 'country-flag-icons/react/3x2'
import React from 'react'

const flagDefaultProps = {
  className: 'w-4 h-4',
}

const LOCALE_TO_FLAG = {
  en: <GB />,
  es: <ES />,
  de: <DE />,
  it: <IT />,
  pt: <PT />,
  fr: <FR />,
}

export function LanguageSelector(props: MenuButtonProps) {
  const router = useRouter()

  if (!router.locales?.length) {
    return null
  }

  const handleLanguageChange = (locale: string) => {
    try {
      Cookies.set('NEXT_LOCALE', locale, { expires: 365 })
    } catch {
      //ignore
    }
    router
      .push(router.pathname, router.asPath, {
        locale,
      })
      .then(() => router.reload())
  }

  return (
    <Menu>
      <MenuButton
        {...props}
        className={`inline-flex font-medium items-center gap-1 rounded-lg bg-transparent py-1 px-2 text-sm leading-8 font-medium group/button text-white focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white ${props.className ?? ''}`}
      >
        <GlobeIcon />
        {getLangNameFromCode(router.locale)?.native}
        <ChevronDown className="w-2 h-2 ml-1 rotate-180 group-data-[active]/button:rotate-0" />
      </MenuButton>
      <Transition
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          {...props}
          anchor="bottom end"
          className="w-52 origin-top-right rounded-lg border bg-white [--anchor-gap:var(--spacing-1)] focus:outline-none font-sans"
        >
          {router.locales.map((locale) => (
            <MenuItem key={locale}>
              <button
                className="group flex w-full items-center gap-2 py-1.5 px-3 text-sm font-medium font-sans data-[focus]:bg-mydra-black/90 data-[focus]:text-white"
                onClick={() => handleLanguageChange(locale)}
              >
                {React.cloneElement(LOCALE_TO_FLAG[locale], flagDefaultProps)}

                {getLangNameFromCode(locale)?.native}
              </button>
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  )
}
