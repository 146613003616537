import * as React from 'react'
import { SVGProps } from 'react'

type MydraLogoProps = SVGProps<SVGSVGElement> & {
  showByStudentFinance?: boolean
}

export function MydraLogo({ showByStudentFinance, ...props }: MydraLogoProps) {
  return (
    <svg
      {...props}
      viewBox="0 0 236 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9698_1503)">
        <path
          d="M18.8026 26.968L20.0074 25.468C24.5407 28.2337 30.1661 27.7263 33.5813 23.4754C37.2916 18.8573 35.8248 13.0122 31.2067 9.30189L20.0406 0.336914L14.3192 7.46056L16.8672 8.34063L15.6827 9.81665C11.1495 7.05096 5.50922 7.57679 2.09408 11.8277C-1.61626 16.4458 -0.134707 22.2724 4.48339 25.9828L15.5812 34.8961L21.2879 27.789L18.8008 26.968H18.8026ZM12.6513 13.4716C14.9539 10.6045 19.1476 10.1469 22.0148 12.4495C24.882 14.7521 25.3396 18.944 23.037 21.813C20.7344 24.6802 16.5406 25.1377 13.6735 22.8351C10.8063 20.5326 10.3487 16.3388 12.6513 13.4716Z"
          fill="currentColor"
        />
        <path
          d="M62.2562 8.66895C60.6953 8.66895 57.9019 9.1136 56.5809 12.0583C55.5772 9.74644 53.8299 8.66895 51.0993 8.66895C48.5642 8.66895 46.6251 9.89404 45.6306 12.121H44.555L45.769 9.25197H41.71V27.4107H45.7192V18.0195C45.7192 13.6044 47.8428 12.6763 49.6233 12.6763C51.8982 12.6763 53.0993 13.9586 53.0993 16.3867V27.4107H57.1085V17.9808C57.1085 14.5103 58.472 12.6763 61.0514 12.6763C63.3004 12.6763 64.4886 13.9586 64.4886 16.3867V27.4107H68.4979V16.193C68.4979 11.4107 66.223 8.66895 62.2562 8.66895Z"
          fill="currentColor"
        />
        <path
          d="M84.011 9.25271L79.4243 21.2454H78.2011L73.6143 9.25271H69.166L75.2269 25.6605H78.5018L77.3782 29.107C76.8819 30.703 76.0479 31.4465 74.7527 31.4465C73.4575 31.4465 72.7914 30.7454 72.7914 29.524V29.0351H68.8984V29.524C68.8984 32.8727 71.0866 34.8709 74.7509 34.8709C78.0774 34.8709 79.8948 33.1882 81.4538 28.6624L88.4612 9.24902H84.0129L84.011 9.25271Z"
          fill="currentColor"
        />
        <path
          d="M103.073 11.989C101.754 9.84506 99.6154 8.66793 97.0305 8.66793C94.6227 8.66793 92.4142 9.57937 90.8072 11.2344C89.0913 13.0019 88.1836 15.4558 88.1836 18.3303C88.1836 21.2049 89.0913 23.6588 90.8072 25.4263C92.4142 27.0813 94.6227 27.9927 97.0305 27.9927C99.7316 27.9927 101.816 26.9226 103.073 24.8968V27.4097H107.082V0H103.073V11.989ZM103.267 18.3303C103.267 21.7584 101.088 24.061 97.846 24.061C94.6043 24.061 92.4253 21.7584 92.4253 18.3303C92.4253 14.9023 94.6043 12.5978 97.846 12.5978C101.088 12.5978 103.267 14.9004 103.267 18.3303Z"
          fill="currentColor"
        />
        <path
          d="M113.768 12.9063V9.25131H109.759V27.41H113.768V19.3418C113.768 15.2716 115.552 13.3768 119.382 13.3768H120.065V8.70703H119.694C116.892 8.70703 114.75 10.231 113.768 12.9063Z"
          fill="currentColor"
        />
        <path
          d="M135.581 9.25295V11.7659C134.324 9.74004 132.239 8.66992 129.538 8.66992C127.131 8.66992 124.922 9.58136 123.315 11.2364C121.599 13.0039 120.691 15.4578 120.691 18.3323C120.691 21.2069 121.599 23.6608 123.315 25.4283C124.922 27.0833 127.131 27.9947 129.538 27.9947C132.297 27.9947 134.413 26.8785 135.66 24.7678H136.736L135.525 27.4117H139.59V9.25295H135.581ZM135.774 18.3323C135.774 21.7604 133.596 24.063 130.354 24.063C127.112 24.063 124.933 21.7604 124.933 18.3323C124.933 14.9043 127.112 12.5998 130.354 12.5998C133.596 12.5998 135.774 14.9024 135.774 18.3323Z"
          fill="currentColor"
        />
      </g>
      {showByStudentFinance && (
        <>
          <path
            d="M233.643 15.0297C233.148 15.0297 232.711 14.9213 232.332 14.7045C231.959 14.481 231.668 14.1694 231.458 13.7698C231.255 13.3634 231.153 12.896 231.153 12.3676C231.153 11.8461 231.255 11.3889 231.458 10.996C231.661 10.6031 231.946 10.3017 232.312 10.0917C232.684 9.87498 233.107 9.7666 233.582 9.7666C234.334 9.7666 234.923 9.99352 235.35 10.4473C235.783 10.9012 236 11.5176 236 12.2965C236 12.5065 235.993 12.6792 235.979 12.8147H232.342C232.376 13.194 232.505 13.4887 232.728 13.6986C232.958 13.9086 233.267 14.0136 233.653 14.0136C233.903 14.0136 234.117 13.9628 234.293 13.8612C234.469 13.7596 234.598 13.6174 234.679 13.4345H235.919C235.763 13.9357 235.492 14.3286 235.106 14.6131C234.72 14.8908 234.232 15.0297 233.643 15.0297ZM234.781 11.88V11.8495C234.781 11.5176 234.676 11.25 234.466 11.0468C234.256 10.8436 233.961 10.742 233.582 10.742C233.223 10.742 232.931 10.847 232.708 11.057C232.484 11.2602 232.362 11.5345 232.342 11.88H234.781Z"
            fill="currentColor"
          />
          <path
            d="M228.106 15.0297C227.612 15.0297 227.178 14.9179 226.806 14.6944C226.433 14.4641 226.145 14.1525 225.942 13.7596C225.746 13.36 225.647 12.9129 225.647 12.4184C225.647 11.9037 225.752 11.4464 225.962 11.0468C226.172 10.6404 226.467 10.3254 226.846 10.1019C227.232 9.87837 227.673 9.7666 228.167 9.7666C228.804 9.7666 229.329 9.93594 229.742 10.2746C230.155 10.6133 230.389 11.057 230.443 11.6056H229.214C229.166 11.3956 229.051 11.2195 228.868 11.0773C228.685 10.935 228.448 10.8639 228.157 10.8639C227.771 10.8639 227.463 11.0028 227.232 11.2805C227.009 11.5582 226.897 11.9308 226.897 12.3981C226.897 12.8723 227.009 13.2482 227.232 13.5259C227.463 13.7969 227.768 13.9323 228.147 13.9323C228.431 13.9323 228.668 13.8544 228.858 13.6986C229.055 13.5429 229.18 13.3397 229.234 13.089H230.463C230.416 13.4345 230.291 13.7562 230.088 14.0543C229.891 14.3523 229.624 14.5894 229.285 14.7655C228.946 14.9416 228.553 15.0297 228.106 15.0297Z"
            fill="currentColor"
          />
          <path
            d="M220.049 9.91901H221.116V10.4981C221.258 10.2611 221.458 10.0816 221.715 9.95965C221.979 9.83095 222.274 9.7666 222.599 9.7666C223.215 9.7666 223.696 9.95287 224.042 10.3254C224.394 10.698 224.57 11.2161 224.57 11.8799V14.8772H223.361V12.0019C223.361 11.6226 223.273 11.3381 223.097 11.1484C222.928 10.9587 222.68 10.8639 222.355 10.8639C222.023 10.8639 221.756 10.9723 221.553 11.189C221.356 11.4058 221.258 11.6903 221.258 12.0425V14.8772H220.049V9.91901Z"
            fill="currentColor"
          />
          <path
            d="M216.045 15.0297C215.469 15.0297 215.012 14.8908 214.673 14.6131C214.334 14.3286 214.165 13.9459 214.165 13.465C214.165 12.9976 214.317 12.6217 214.622 12.3372C214.934 12.0459 215.384 11.88 215.974 11.8393L217.355 11.758V11.5142C217.355 11.25 217.288 11.0604 217.152 10.9452C217.017 10.8233 216.803 10.7623 216.512 10.7623C216.228 10.7623 216.014 10.8063 215.872 10.8944C215.73 10.9825 215.642 11.1281 215.608 11.3313H214.409C214.51 10.2882 215.239 9.7666 216.593 9.7666C217.196 9.7666 217.664 9.91562 217.995 10.2137C218.334 10.5049 218.504 10.9147 218.504 11.4431V13.7495C218.504 13.8511 218.554 13.9019 218.656 13.9019H219.093V14.8772H218.178C218.002 14.8772 217.863 14.8332 217.762 14.7452C217.667 14.6503 217.62 14.5182 217.62 14.3489V14.3083C217.477 14.5318 217.267 14.7079 216.99 14.8366C216.719 14.9653 216.404 15.0297 216.045 15.0297ZM215.374 13.4243C215.374 13.614 215.445 13.763 215.587 13.8714C215.737 13.9798 215.943 14.0339 216.207 14.0339C216.553 14.0339 216.83 13.9425 217.04 13.7596C217.25 13.57 217.355 13.299 217.355 12.9468V12.6725L216.156 12.7741C215.899 12.7944 215.703 12.8655 215.567 12.9874C215.438 13.1026 215.374 13.2482 215.374 13.4243Z"
            fill="currentColor"
          />
          <path
            d="M208.657 9.91901H209.724V10.4981C209.866 10.2611 210.066 10.0816 210.324 9.95965C210.588 9.83095 210.882 9.7666 211.207 9.7666C211.824 9.7666 212.305 9.95287 212.65 10.3254C213.002 10.698 213.179 11.2161 213.179 11.8799V14.8772H211.969V12.0019C211.969 11.6226 211.881 11.3381 211.705 11.1484C211.536 10.9587 211.289 10.8639 210.964 10.8639C210.632 10.8639 210.364 10.9723 210.161 11.189C209.965 11.4058 209.866 11.6903 209.866 12.0425V14.8772H208.657V9.91901Z"
            fill="currentColor"
          />
          <path
            d="M206.009 9.91862H207.218V14.8769H206.009V9.91862ZM205.969 7.81543H207.269V9.05499H205.969V7.81543Z"
            fill="currentColor"
          />
          <path
            d="M200.415 7.76465H204.774V8.93309H201.665V10.6603H204.774V11.8288H201.665V14.8769H200.415V7.76465Z"
            fill="currentColor"
          />
          <path
            d="M197.816 14.877C197.376 14.877 197.054 14.7788 196.851 14.5823C196.648 14.3859 196.546 14.0642 196.546 13.6171V10.9856H195.276V9.91875H196.546V8.46582H197.755V9.91875H199.269V10.9856H197.755V13.3428C197.755 13.5053 197.789 13.6239 197.857 13.6984C197.932 13.7729 198.054 13.8102 198.223 13.8102H199.269V14.877H197.816Z"
            fill="currentColor"
          />
          <path
            d="M190.151 9.91901H191.218V10.4981C191.36 10.2611 191.56 10.0816 191.818 9.95965C192.082 9.83095 192.376 9.7666 192.702 9.7666C193.318 9.7666 193.799 9.95287 194.144 10.3254C194.497 10.698 194.673 11.2161 194.673 11.8799V14.8772H193.464V12.0019C193.464 11.6226 193.376 11.3381 193.199 11.1484C193.03 10.9587 192.783 10.8639 192.458 10.8639C192.126 10.8639 191.858 10.9723 191.655 11.189C191.459 11.4058 191.36 11.6903 191.36 12.0425V14.8772H190.151V9.91901Z"
            fill="currentColor"
          />
          <path
            d="M186.691 15.0297C186.197 15.0297 185.76 14.9213 185.381 14.7045C185.008 14.481 184.717 14.1694 184.507 13.7698C184.304 13.3634 184.202 12.896 184.202 12.3676C184.202 11.8461 184.304 11.3889 184.507 10.996C184.71 10.6031 184.995 10.3017 185.36 10.0917C185.733 9.87498 186.156 9.7666 186.63 9.7666C187.382 9.7666 187.972 9.99352 188.398 10.4473C188.832 10.9012 189.049 11.5176 189.049 12.2965C189.049 12.5065 189.042 12.6792 189.028 12.8147H185.391C185.425 13.194 185.553 13.4887 185.777 13.6986C186.007 13.9086 186.315 14.0136 186.702 14.0136C186.952 14.0136 187.166 13.9628 187.342 13.8612C187.518 13.7596 187.647 13.6174 187.728 13.4345H188.967C188.812 13.9357 188.541 14.3286 188.155 14.6131C187.768 14.8908 187.281 15.0297 186.691 15.0297ZM187.829 11.88V11.8495C187.829 11.5176 187.724 11.25 187.514 11.0468C187.304 10.8436 187.01 10.742 186.63 10.742C186.271 10.742 185.98 10.847 185.757 11.057C185.533 11.2602 185.411 11.5345 185.391 11.88H187.829Z"
            fill="currentColor"
          />
          <path
            d="M180.348 15.0293C179.881 15.0293 179.468 14.9209 179.109 14.7042C178.75 14.4806 178.469 14.1724 178.265 13.7796C178.069 13.3799 177.971 12.9193 177.971 12.3978C177.971 11.8762 178.069 11.419 178.265 11.0261C178.469 10.6265 178.75 10.3183 179.109 10.1015C179.468 9.878 179.881 9.76623 180.348 9.76623C180.667 9.76623 180.954 9.82381 181.212 9.93896C181.476 10.0473 181.683 10.2031 181.832 10.4063V7.76465H183.041V14.8769H181.974V14.2876C181.818 14.5179 181.595 14.7008 181.303 14.8362C181.019 14.9649 180.7 15.0293 180.348 15.0293ZM179.22 12.3978C179.22 12.8584 179.342 13.2309 179.586 13.5154C179.83 13.7931 180.148 13.932 180.541 13.932C180.934 13.932 181.249 13.7931 181.486 13.5154C181.73 13.2309 181.852 12.8584 181.852 12.3978C181.852 11.9372 181.73 11.568 181.486 11.2903C181.249 11.0058 180.934 10.8635 180.541 10.8635C180.148 10.8635 179.83 11.0058 179.586 11.2903C179.342 11.568 179.22 11.9372 179.22 12.3978Z"
            fill="currentColor"
          />
          <path
            d="M174.36 15.0296C173.75 15.0296 173.273 14.8433 172.927 14.4708C172.582 14.0982 172.409 13.5801 172.409 12.9162V9.91895H173.618V12.7943C173.618 13.553 173.943 13.9323 174.594 13.9323C174.919 13.9323 175.176 13.8273 175.366 13.6173C175.562 13.4006 175.66 13.1127 175.66 12.7537V9.91895H176.87V14.8772H175.803V14.3997C175.64 14.6029 175.434 14.7586 175.183 14.867C174.932 14.9754 174.658 15.0296 174.36 15.0296Z"
            fill="currentColor"
          />
          <path
            d="M170.075 14.877C169.635 14.877 169.313 14.7788 169.11 14.5823C168.907 14.3859 168.805 14.0642 168.805 13.6171V10.9856H167.535V9.91875H168.805V8.46582H170.014V9.91875H171.528V10.9856H170.014V13.3428C170.014 13.5053 170.048 13.6239 170.116 13.6984C170.19 13.7729 170.312 13.8102 170.482 13.8102H171.528V14.877H170.075Z"
            fill="currentColor"
          />
          <path
            d="M164.315 15.0293C163.414 15.0293 162.709 14.8228 162.201 14.4096C161.693 13.9896 161.432 13.4105 161.419 12.6721H162.699C162.706 13.0515 162.852 13.3529 163.136 13.5764C163.427 13.7999 163.82 13.9117 164.315 13.9117C164.748 13.9117 165.09 13.8135 165.341 13.6171C165.598 13.4139 165.727 13.1429 165.727 12.8042C165.727 12.5265 165.635 12.3098 165.453 12.154C165.27 11.9914 164.992 11.8864 164.619 11.839L163.746 11.7171C162.344 11.5342 161.642 10.8704 161.642 9.72565C161.642 9.30569 161.751 8.93653 161.968 8.61818C162.184 8.29305 162.489 8.04581 162.882 7.87647C163.282 7.70036 163.739 7.6123 164.254 7.6123C165.073 7.6123 165.717 7.79858 166.184 8.17112C166.658 8.54367 166.902 9.0483 166.916 9.68501H165.646C165.632 9.3802 165.5 9.14313 165.249 8.97379C165.006 8.79768 164.674 8.70962 164.254 8.70962C163.854 8.70962 163.532 8.79429 163.288 8.96363C163.051 9.13297 162.933 9.35988 162.933 9.64437C162.933 9.90177 163.028 10.105 163.217 10.254C163.414 10.3962 163.715 10.4945 164.122 10.5486L164.945 10.6604C166.326 10.8297 167.017 11.5173 167.017 12.723C167.017 13.1903 166.909 13.5967 166.692 13.9422C166.475 14.2876 166.164 14.5552 165.757 14.7449C165.351 14.9345 164.87 15.0293 164.315 15.0293Z"
            fill="currentColor"
          />
          <path
            d="M153.202 15.6291H154.167C154.309 15.6291 154.421 15.602 154.503 15.5478C154.591 15.4936 154.662 15.4089 154.716 15.2938L155.072 14.5013L153.029 9.91895H154.411L155.722 13.1194L157.022 9.91895H158.374L155.691 15.9339C155.576 16.2048 155.427 16.4012 155.244 16.5232C155.061 16.6451 154.801 16.706 154.462 16.706H153.202V15.6291Z"
            fill="currentColor"
          />
          <path
            d="M150.369 15.0293C150.01 15.0293 149.685 14.9649 149.393 14.8362C149.109 14.7008 148.889 14.5179 148.733 14.2876V14.8769H147.666V7.76465H148.875V10.4063C149.024 10.2031 149.231 10.0473 149.495 9.93896C149.759 9.82381 150.05 9.76623 150.369 9.76623C150.836 9.76623 151.249 9.878 151.608 10.1015C151.967 10.3183 152.245 10.6265 152.441 11.0261C152.645 11.419 152.746 11.8762 152.746 12.3978C152.746 12.9193 152.645 13.3799 152.441 13.7796C152.245 14.1724 151.967 14.4806 151.608 14.7042C151.249 14.9209 150.836 15.0293 150.369 15.0293ZM148.855 12.3978C148.855 12.8584 148.977 13.2309 149.221 13.5154C149.464 13.7931 149.783 13.932 150.176 13.932C150.568 13.932 150.887 13.7897 151.131 13.5052C151.375 13.2207 151.496 12.8516 151.496 12.3978C151.496 11.9439 151.375 11.5748 151.131 11.2903C150.887 11.0058 150.568 10.8635 150.176 10.8635C149.783 10.8635 149.464 11.0058 149.221 11.2903C148.977 11.568 148.855 11.9372 148.855 12.3978Z"
            fill="currentColor"
          />
        </>
      )}
      <defs>
        <clipPath id="clip0_9698_1503">
          <rect width="139.589" height="34.8968" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
